import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyC93fy63AsdCpdNgOiH6PMOhpzzuODeVSk",
  authDomain: "semaforo-5dde9.firebaseapp.com",
  databaseURL: "https://semaforo-5dde9-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "semaforo-5dde9",
  storageBucket: "semaforo-5dde9.appspot.com",
  messagingSenderId: "102327744492",
  appId: "1:102327744492:web:f9ac8f7357e0fc8b51f2c9"
};


firebase.initializeApp(firebaseConfig);
var database = firebase.database();

export default database;
