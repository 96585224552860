import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Use your config values here.
/*
firebase.initializeApp({
  apiKey: "AIzaSyBV4-rUdTgS1IvkugUy66D7Dc8WoF_XYJM",
  authDomain: "semaforo-lg-1.firebaseapp.com",
  databaseURL: "https://semaforo-lg-1.firebaseio.com",
  projectId: "semaforo-lg-1",
  storageBucket: "rsemaforo-lg-1.appspot.com",
  messagingSenderId: "508016495868",
  appId: "1:508016495868:web:48611c4d43393412069b79"
});*/




ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
