import React from "react";
import  {useState, useEffect} from 'react';
import logo from './logo.png';
import './App.css';
import database from './firebase';

function App() {

  let [countNum, setCount] = useState(0);

  let increaseValue = () => {
    if (countNum === 13) {
      //alert("Copa cheia ✋🏻");
    } else {
      setCount((countNum += 1));
      database.ref("ocupantes").set({
        ocupante_id : countNum,
      }).catch(alert);
    }
  }
  let decreaseValue = () => {
    if (countNum < 1) {
      //alert("Copa vazia 👌🏻");
    } else {
      setCount((countNum -= 1));
      database.ref("ocupantes").set({
        ocupante_id : countNum,
      }).catch(alert);
    }
  }

  const MINUTE_MS = 1500;
  useEffect(() => {
    const interval = setInterval(() => {
      let dbRef = database.ref('ocupantes');
      dbRef.child("ocupante_id").get().then((snapshot) => {
        if (snapshot.exists()) {
          setCount(snapshot.val())
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [])


  // Push Function
  return (
    <div className="App">
      <header className="App-header">
        <img width="150" src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        {countNum < 13 &&
          <div className="result free">Podes vir 🍽️</div>
        }
        {countNum === 13 &&
          <div className="result full">Aguarda um pouco ✋🏻</div>
        }
        <p>Lotação atual: <strong>{countNum}</strong></p>
        <div className="wrap">
          <button className="button button--fill" onClick={increaseValue}>Entrar</button>
          <button className="button" onClick={decreaseValue}>Sair</button>
      	</div>
      </main>
    </div>
  );
}

export default App;
